//
// Utils
//

// Mixins

// Mobile first media queries
@mixin respond-to($size) {
  @media only screen and (min-width: $size) {
    @content;
  }
}

// Helpers
.full-width {
  width: 100%;
}

.max-full-width {
  max-width: 100%;
}

.align-left {
  display: inline;
  float: left;
  margin-right: 1.5#{$wb-base-unit};
}

.align-right {
  display: inline;
  float: right;
  margin-left: 1.5#{$wb-base-unit};
}

.centered,
.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Text alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

//
// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
//
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

//
// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    `contenteditable` attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
.clearfix {
  &:before,
  &:after {
    content: ' '; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}
