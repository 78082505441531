/**
 * Footer
 */

// .site-footer {}

.site-footer {
  padding: 2em 0;
  font-family: $wb-goudy-old;
}

.dr-names {
  margin-top: 1em;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  strong {
    color: $wb-brown;
  }

  em {
    color: $wb-gray-light;
  }
}

.site-footer-bottom {
  background-color: $wb-brown;
  color: $wb-white;
  font-family: $wb-goudy-old;

  .social-icons {
    text-align: right; 
  }

  .social-icon {
    display: inline-block;
    color: $wb-white;
    font-size: 2em;
    padding-left: 20px;
  }
}
