/**
 * Main
 */

.container {
  margin: 0 auto;
  padding-left: 1#{$wb-base-unit};
  padding-right: 1#{$wb-base-unit};
  max-width: $wb-container-width;
}

.profile-pic {
	margin-right: 1em;
	float: left;
}

.award-pic {
	display: block;
    margin-left: auto;
    margin-right: auto;
}

.bible-verse {
  margin-top: -2em;
	font-style: italic;
	font-size: 1em;
	color: #72222B;
	text-align: center;
	float: left;

  @include respond-to(48em) {
	  margin-top: 70px;
	  font-size: 2em;
  }
}

.page-main {
	padding: 2em 0;
	font-family: "goudy-old-style", sans-serif;
	text-align: left;
}

.section-award {
	margin-top: 2em;
}

.nav-side ul {
	display: block;
    width: 260px;
    list-style: none;
}

.nav-side li {
	padding-bottom: 2px;
	display: block;
}

.nav-side a, {
	padding: .5em 0;
	width: 100%;
	background-color: #72222B;
	color: white;
	display: block;
	text-align: center;
	border-radius: 4px;
}

.red-button {
	padding: .5em .5em;
	width: 100%;
	background-color: #72222B;
	color: white;
	text-align: center;
	border-radius: 4px;
}

.text-centered {
	text-align: center;
}

.spacing-top {
	padding-top: 5px;
}

.spacing-bottom {
	padding-bottom: 15px;
}

.section-staff {
	padding-top: 10px;
	padding-bottom: 20px;
}
