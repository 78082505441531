/**
 * Feedback
 */

[class^="alert-"] {
  margin: 1em 0;
  padding: .618em;
  border: 1px solid;

  p {
    margin: 0;
  }
}

@mixin alert($wb-color) {
  border-color: lighten($wb-color, 30%);
  background-color: lighten($wb-color, 45%);

  strong {
    color: $wb-color;
  }
}

.alert-info {
  @include alert($wb-feedback-color-information);
}

.alert-error {
  @include alert($wb-feedback-color-error);
}

.alert-warning {
  @include alert($wb-feedback-color-warning);
}

.alert-success {
  @include alert($wb-feedback-color-success);
}
