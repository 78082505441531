/*
 * Base
 *
 * Update the foundational and global aspects of the page.
 */

// http://www.paulirish.com/2012/box-sizing-border-box-ftw/
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $wb-text-color;
  font-family: $wb-body-font;
  font-size: $wb-font-size;
  line-height: $wb-line-height;
  text-size-adjust: 100%;
  background-color: $wb-body-bg;
}

/* No `:visited` state is required by default (browsers will use `a`) */
a {
  color: $wb-link-color;
  text-decoration: $wb-link-decoration;

  /* `:focus` is linked to `:hover` for basic accessibility */
  &:hover,
  &:focus {
    text-decoration: $wb-link-hover-decoration;
  }

  strong {
    color: inherit;
  }
}

hr {
  margin: 2em 0;
  border-top: 1px solid $wb-gray-lighter;
  border-bottom: 0;
}
