// Scaffold
//
// Global styles.

$wb-base-unit: 'rem' !default;
$wb-baseline: 1#{$wb-base-unit} !default;
$wb-grid-gutter: 1% !default;
$wb-container-width: 75#{$wb-base-unit} !default; // 980px
$wb-body-bg: #fff !default;

// Colors

// Brand colors
$wb-brand-primary: #3498db;

// Custom colors
$wb-gray-base: #000;

$wb-gray-darker: #222;
$wb-gray-dark: #333;
$wb-gray: #555;
$wb-gray-light: #777;
$wb-gray-lighter: #eee;

$wb-brown: rgba(113, 34, 43, 1);
$wb-white: #fff;

// Typography

$wb-goudy-old: "goudy-old-style",sans-serif;
$wb-body-font: "industry",sans-serif;
$wb-mono-font: 'Menlo', Inconsolata, Consolas, Monaco, monospace;

$wb-font-size: 16px;

$wb-line-height: 1.618 !default;

$wb-text-color: #333 !default;
$wb-text-margin-top: $wb-baseline !default;
$wb-text-margin-bottom: $wb-baseline !default;

$wb-link-color: $wb-brand-primary !default;
$wb-link-decoration: none;
$wb-link-hover-decoration: none;
$wb-link-hover-color: darken($wb-link-color, 15%) !default;

// http://www.modularscale.com/?1,.5&em&1.618&sass&text
$wb-font-size-h1: 2.618#{$wb-base-unit};
$wb-font-size-h2: 2.118#{$wb-base-unit};
$wb-font-size-h3: 1.618#{$wb-base-unit};
$wb-font-size-h4: 1.309#{$wb-base-unit};
$wb-font-size-h5: 1#{$wb-base-unit};
$wb-font-size-h6: 1#{$wb-base-unit};

$wb-headings-font: inherit;
$wb-headings-font-weight: 500;
$wb-headings-line-height: 1.125;
$wb-headings-color: $wb-text-color;
$wb-headings-margin-bottom: $wb-text-margin-bottom;

// Border radius

$wb-border-radius-base: 4px;
$wb-border-radius-large: 6px;
$wb-border-radius-small: 3px;

// Buttons

$wb-btn-border-radius: $wb-border-radius-base;

$wb-btn-default-bg: transparent;
$wb-btn-default-color: #333;
$wb-btn-default-border: #ccc;

$wb-btn-primary-bg: $wb-brand-primary;
$wb-btn-primary-color: #fff;
$wb-btn-primary-border: lighten($wb-btn-primary-bg, 20%);

// Forms

$wb-form-display: block;

$wb-form-border-radius: 5px;
$wb-form-border-width: 1px;
$wb-form-border-style: solid;
$wb-form-border-color: #ccc;

$wb-form-input-height: 40px;
$wb-form-input-width: 100%;
$wb-form-input-max-width: 400px;
$wb-form-input-bg-color: #fff;
$wb-form-focus-bg-color: lighten($wb-gray-lighter, 5%);
$wb-form-focus-border-color: $wb-brand-primary;
$wb-form-focus-font-color: #aaa;

// Icons

$wb-icon-roundness: 3.778px;
$wb-icon-border-color: #333;

// Feedback

$wb-feedback-color-information: #439ccf;
$wb-feedback-color-error: #e72915;
$wb-feedback-color-warning: #d35400;
$wb-feedback-color-success: #74a730;
