/**
 * Home page
 */

/*
 * Slider
 */

.site-slider {
  background-image: url('../img/slide.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 120%;
  background-color: #71222B;

  @include respond-to(48em) {
    background-size: auto;
  }
}

.slide-content {
  text-align: center;
  padding: 0;
  color: $wb-white;

  h2 {
    color: $wb-brown;
  }

  h2,
  p {
    font-size: 1.5em;
    margin: 0;
    text-shadow: 0 0 .5em rgba(0,0,0,0.9);

    @include respond-to(48em) {
      font-size: 3.4em;
    }
  }

  @include respond-to(48em) {
    padding: 8em 0;
  }
}

/*
 * CTA
 */

.home-cta {
  padding: 1em 0;
  text-align: center;
  background-image: url('../img/texture.jpg');
  color: $wb-white;
}

.cta-big-text {
  text-transform: uppercase;
  font-size: 1.5em;
}

/*
 * Main
 */

.main {
  padding: 2em 0;
  font-family: $wb-goudy-old;
  text-align: center;
}

/*
 * Boxes
 */

.home-boxes {
  color: $wb-white;
}

.box-doctor,
.box-mom {
  position: relative;
  padding: 2em;
  height: 320px;
  max-height: 320px;
  overflow: hidden;
  background-size: 100% auto;

  img {
    width: 40%;
    position: absolute;
    bottom: -2em;
    right: -2em;
  }
}

.box-mom {
  background-size: 200%;
  background-position-x: -100%;
}

.box-doctor {
  background-image: url('../img/left_box_bg.jpg');

  img {
    margin-bottom: -28%;
  }
}

.box-mom {
  background-image: url('../img/right_box_bg.jpg');
}
