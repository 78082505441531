/**
 * Sidebar
 */

// .site-sidebar {}


.nav-side {
  margin-left: 0;
  padding-left: 0;
}

.nav-side li {
  display: block;
}

.nav-side a {
  margin-bottom: 2px;
  display: block;
  padding: .5em 0;
  background-color: #72212A;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}

.nav-side a:hover {
  background-color: #4B151A;
}