/**
 * Images
 */

figure,
img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 0 1#{$wb-base-unit};
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}
