/**
 * Header
 */

// .masthead {}

/*
 * Header
 */

.site-header {
  max-height: auto;

  @include respond-to(48em) {
    max-height: 3.6em;
  }
}

/*
 * Logo
 */

.site-logo {
  width: 80%;
  max-width: 80%;
  margin: 0 auto;

  @include respond-to(48em) {
    max-width: 120%;
    width: 120%;
    margin-left: -10%;
  }
}

/*
 * Navigation
 */

.mobile-menu-button {
  display: block;
  padding: .2em;
  margin: .5em auto .8em;
  width: 100%;
  font-size: 1.2em;
  color: $wb-white;
  background-color: $wb-brown;
  border: 0;
  outline: 0;

  @include respond-to(48em) {
    display: none;
  }
}

/* Mobile navigation */
.mobile-nav {
	display: none !important;
  margin-bottom: .8em;

  &.show {
    display: block !important;
  }

  .mobile-nav-item {
    margin-bottom: 2px;
    padding: .3em .5em;
    display: block;
    color: $wb-white;
    background-color: $wb-brown;
  }

  @include respond-to(48em) {
    display: none;
  }
}

.nav-item {
  display: inline-block;
  padding: 1em 1em;
  color: $wb-gray-light;
  font-weight: bold;
}

.site-nav-left {
  display: none;
  float: right;

  .nav-item {
    border-left: 1px solid $wb-gray-lighter;
  }

  @include respond-to(48em) {
    display: block;
  }
}

.site-nav-right {
  display: none;

  .nav-item {
    border-right: 1px solid $wb-gray-lighter;
  }

  @include respond-to(48em) {
    display: block;
  }
}
