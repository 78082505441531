/**
 * Buttons
 */

// Basic button structure
%button-static {
  display: inline-block;
  padding: .5em 2em;
  border: 0;
  text-decoration: none;
  text-align: center;
  font-family: inherit;
  font-size: inherit;
  white-space: nowarp;
  line-height: 1;
  cursor: pointer;
  border-radius: $wb-btn-border-radius;

  &:focus {
    outline: none;
  }
}

// Style the button
@mixin button($background-color, $text-color, $border-color) {
  color: $text-color;
  background-color: $background-color;
  border: 1px solid $border-color;

  &:hover {
    background-color: darken($background-color, 5%);
    color: $text-color;
    text-decoration: none;
    border-color: darken($background-color, 5%);
  }

  &:active {
    background-color: darken($background-color, 2%);
    border-color: darken($background-color, 2%);
  }
}

a.button,
button.button,
.button {
  @extend %button-static;

  @include button($wb-btn-default-bg, $wb-btn-default-color, lighten($wb-btn-default-color, 40%));

  &:hover {
    border-color: lighten($wb-btn-default-color, 10%);
  }
}


a.button-primary,
button.button-primary,
.button-primary {
  @extend .button;

  @include button($wb-btn-primary-bg, $wb-btn-primary-color, $wb-btn-primary-bg);
}
