/**
 * Navigation
 */

// .main-navigation {}

/*
 * Menu horizontal
 */

.menu-h {
  @extend .clearfix;

  display: block;

  .menu-item {
    float: left;
    margin-right: 1em;
  }
}

/*
 * Menu vertical
 */

// .menu-v {}

/*
 * Menu list
 */

.menu-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
