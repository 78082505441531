/**
 * Tables
 */

table {
  margin-bottom: 1#{$wb-base-unit};
  width: 100%;
  font-size: 85%;
  border: 1px solid lighten($wb-text-color, 50%);
  border-collapse: collapse;
}

td,
th {
  padding: .25#{$wb-base-unit} .5#{$wb-base-unit};
  border: 1px solid lighten($wb-text-color, 70%);
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: lighten($wb-text-color, 75%);
}
